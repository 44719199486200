import multiguard from "vue-router-multiguard"
import { authenticated, authorized } from "@/config/router/guards"

export default [
  {
    path: "/plan-management/plan-list",
    name: "PlanList",
    component: () => import(/* webpackChunkName: "PlanList" */ "@/pages/PlanManagement/PlanList"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "plan_list",
      permissionType: "plan_management"
    }
  },
  {
    path: "/plan-management/base-rate-period-settings",
    name: "BaseRatePeriodSettings",
    component: () =>
      import(/* webpackChunkName: "BaseRatePeriodSettings" */ "@/pages/PlanManagement/BaseRatePeriodSettings"),
    beforeEnter: multiguard([authenticated, authorized]),
    meta: {
      title: "base_rate_period_settings",
      permissionType: "plan_management"
    }
  }
]
